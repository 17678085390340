import styled from "styled-components";

export const PageWrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
`;

export const Container = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 950px;
    display: flex;
    flex-direction: column;
    height: fit-content;

    > div {
        aspect-ratio: unset;
    }
`;

export const Title = styled.h1`
    color: ${props => props.theme.colors.primary};
    width: 100%;
    font-size: ${props => props.theme.fonts.sizes.title};
    border-bottom: 2px solid ${props => props.theme.colors.secondary};
`;