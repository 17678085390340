import React from 'react'
import {graphql} from "gatsby";
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceMachine } from '@HichiHachi/react-slices';
import {PageWrapper, Container, Title} from "styles/page";
import {Sidebar} from "components";
import linkResolver from 'utils/linkresolver';
import Seo from 'utils/seo';
import {GetSocials} from "utils/convertData";

export const query = graphql`
    query PageTemplateQuery($id: String){
        prismicPage(id: {eq: $id}){
            _previewable
            uid
            first_publication_date
            data {
                ...pageQuery
            }
        }
        prismicNavigation {
            data {
                ...socialQuery
            }
        }
        prismicSidebar {
            ...sidebarFragment
        }
    }
`;

const PageTemplate = ({data})  => {
    const page = data?.prismicPage?.data;
    const unpublished = data?.prismicPage?.first_publication_date;
    const sidebarData = data?.prismicSidebar?.data;
    
    const sidebar = page.sidebar;
    const title = page.title;
    const slices = page.body;
    const metaTitle = page.meta_title;
    const metaDescription = page.meta_description;
    const metaImage = page.meta_image;

    const social = GetSocials(data);
	
	const context = {social}

    return (
        <>
            <Seo 
                title={metaTitle ? metaTitle : null}
                description={metaDescription ? metaDescription : null}
                image={metaImage ? metaImage.url : null}
            />
            <PageWrapper>
                <Container>
                    <Title>{title}</Title>
                    <SliceMachine body={slices} context={context}/>
                </Container>
                {!!sidebar && !!unpublished && <Sidebar data={sidebarData} social={social}/>}
            </PageWrapper>
        </>
    )
}

export default withPrismicPreview(PageTemplate, [
    {
        repositoryName: process.env.GATSBY_PRISMIC_SITE_REPO,
        linkResolver,
    },
])